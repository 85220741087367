export default {
  name: 'ChartBipolarBar',

  props: {
    values: {
      required: true,
      type: Array,
    },

    backgrounds: {
      default: () => {
        return ['#FF90B3', '#3F5798'];
      },

      type: Array,
    },

    height: {
      default: 300, //in pixels
      type: Number,
    },
  },

  computed: {
    barUpStyle() {
      return {
        background: this.backgrounds[0],
        color: this.backgrounds[0],
      };
    },

    barDownStyle() {
      const { backgrounds } = this;
      const background = backgrounds[1] || backgrounds[0];

      return {
        background,
        color: background,
      };
    },

    chartData() {
      return this.values.map(({ data1, data2, label }) => {
        return {
          data1,
          data2,
          label,
          style1: { ...this.barUpStyle, height: `${this.getSizeBar(data1)}px` },
          style2: {
            ...this.barDownStyle,
            height: `${this.getSizeBar(data2)}px`,
          },
        };
      });
    },

    maxHeightBar() {
      return (this.height - 60) / 2;
    },

    maxPercentages() {
      const percentages = (this.values || []).map(({ data1, data2 }) => {
        return Math.max(data1, data2);
      });

      return Math.max(...percentages);
    },

    width() {
      return parseInt(100 / this.values.length);
    },
  },

  methods: {
    getSizeBar(percentage) {
      const { maxHeightBar } = this;
      const size = maxHeightBar * (percentage / this.maxPercentages);

      return size > maxHeightBar ? maxHeightBar : parseInt(size);
    },
  },
};
